// import { useCookies } from "react-cookie";
import Axios from "axios";
import {
  PATH,
  SERVICE_NAME,
  API_LOGIN,
  API_VALIDATE,
  PROXY,
  xxx,
  yyy,
  zzz,
  SERVICE_DOMAIN_NAME,
  PAYMENT_CHECK_URL,
  SERVICE_ROUND_ROBIN,
  SERVICE_DOMAIN_GKE,
  SERVICE_DOMAIN_ROUND_ROBIN_GKE
} from "../config/APIShare";

const timeout = 30000; // timeout in ms

export function HTTP_SERVICE() {
  // const [cookies, setCookie] = useCookies(["JSESSIONID"]);

  const get = (url) => {
    let token = `Bearer ${localStorage.getItem("token")}`;
    let fastifyToken = `Bearer ${localStorage.getItem("fastify_token")}`;
    return new Promise((resolve, reject) => {
      validateTokenLogin().then((result) => {
        if (result.isSuccess) {
          token = `Bearer ${localStorage.getItem("token")}`;
          if (url.indexOf("?") > -1) {
            url = url + "&fastify_token=" + fastifyToken;
          } else {
            url = url + "?fastify_token=" + fastifyToken;
          }
          Axios(PATH + PROXY, {
            method: "get",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token,
              "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_NAME + url,
            },
            timeout,
          }).then(
            (result) => {
              resolve(result);
            },
            function (error) {
              reject(error);
            }
          );
        }
      });
    });
  };
  const post = (url, request) => {
    let token = `Bearer ${localStorage.getItem("token")}`;
    let fastifyToken = `Bearer ${localStorage.getItem("fastify_token")}`;
    return new Promise((resolve, reject) => {
      validateTokenLogin().then((result) => {
        if (result.isSuccess) {
          token = `Bearer ${localStorage.getItem("token")}`;
          request.token = fastifyToken;
          Axios(PATH + PROXY, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_NAME + url,
            },
            data: JSON.stringify(request),
            timeout,
          }).then(
            (result) => {
              resolve(result);
            },
            function (error) {
              reject(error);
            }
          );
        }
      });
    });
  };

  const put = (url, request) => {
    const token = `Bearer ${localStorage.getItem("token")}`;
    return Axios(PATH + url, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify(request),
      timeout,
    });
  };

  const deletes = (url) => {
    const token = `Bearer ${localStorage.getItem("token")}`;

    return Axios(PATH + url, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      timeout,
    });
  };

  const login = (url, request) => {
    let token = `Bearer ${localStorage.getItem("token")}`;
    return new Promise((resolve, reject) => {
      validateTokenLogin().then((result) => {
        if (result.isSuccess) {
          token = `Bearer ${localStorage.getItem("token")}`;
          Axios(
            PATH + PROXY,
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization: token,
                "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_NAME + url,
              },
              data: JSON.stringify(request),
              timeout,
            },
            { withCredentials: false }
          ).then(
            (result) => {
              resolve(result);
            },
            function (error) {
              reject(error);
            }
          );
        }
      });
    });
  };

  const checkUrl = (url, request) => {
    let token = `Bearer ${localStorage.getItem("token")}`;
    return new Promise((resolve, reject) => {
      validateTokenLogin().then((result) => {
        if (result.isSuccess) {
          token = `Bearer ${localStorage.getItem("token")}`;
          Axios(PATH + PROXY, {
            method: "post",
            withCredentials: false,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_NAME + url,
            },
            data: JSON.stringify(request),
            timeout,
          }).then(
            (result) => {
              resolve(result);
            },
            function (error) {
              reject(error);
            }
          );
        }
      });
    });
  };

  const consentContentAsync = (request) => {
    const token = `Bearer ${localStorage.getItem("token")}`;
    return Axios(PATH + PROXY, {
      method: "post",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "X-Requested-Url": "/flink/zuul/leads-prospect/consent-content/id",
      },
      data: JSON.stringify(request),
      timeout,
    });
  };

  const acceptConsentAsync = (request) => {
    const token = `Bearer ${localStorage.getItem("token")}`;
    return Axios(PATH + PROXY, {
      method: "post",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_NAME + "/acceptConsent",
      },
      data: JSON.stringify(request),
      timeout,
    });
  };

  const validateTokenLogin = () => {
    return new Promise((resolve, reject) => {
      apiLogin().then(
        (apiLoginResult) => {
          localStorage.setItem("token", apiLoginResult.data.access_token);
          // setCookie("token", apiLoginResult.data.access_token, {
          //   path: "/",
          //   domain: SERVICE_DOMAIN_NAME,
          //   httpOnly: true,
          //   secure: true,
          // });

          //apiLoginResult.data.access_token;
          resolve({ isSuccess: true });
        },
        function (error) {
          resolve({ isSuccess: false });
        }
      );
    });
  };

  const apiLogin = () => {
    const dataToSubmit = {
      username: xxx,
      password: yyy,
      channel: zzz,
    };
    return new Promise((resolve, reject) => {
      Axios(PATH + API_LOGIN, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(dataToSubmit),
        timeout,
      }).then(
        (result) => {
          resolve(result);
        },
        function (error) {
          reject(error);
        }
      );
    });
  };

  const apiValidate = (token) => {
    return new Promise((resolve, reject) => {
      Axios(PATH + API_VALIDATE, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        timeout,
      })
        .then((result) => {
          resolve(result);
        })
        .then((data) => {
          // console.log(data);
        })
        .catch((error) => {
          // console.log(error.response.data.errorCode);
          if (error.response.data.errorCode.indexOf("401") > -1) {
            const response = {
              status: 401,
              statusDescription: "unauthorized",
            };
            resolve(response);
          } else {
            reject(error);
          }
        });
    });
  };

  const checkPayment = (obj) => {
    // console.log(obj);

    const dataToSubmit = {
      channel_code: "PFN",
      ref_no: obj.ref_no,
    };
    return new Promise((resolve, reject) => {
      Axios(PAYMENT_CHECK_URL, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(dataToSubmit),
        timeout,
      })
        .then((result) => {
          // console.log(result);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const generatePaymentURL = (obj) => {
    const dataToSubmit = obj;

    // console.log(dataToSubmit);
    return new Promise((resolve, reject) => {
      Axios("https://payment-uat.prudential.co.id/paylink/getUrlPaymentLink", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(dataToSubmit),
        timeout,
      })
        .then((result) => {
          // console.log(result);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const postBase = (url, payload) => {
    let token = `Bearer ${localStorage.getItem("token")}`;
    return new Promise((resolve, reject) => {
      validateTokenLogin().then((result) => {
        if (result.isSuccess) {
          token = `Bearer ${localStorage.getItem("token")}`;
          Axios(PATH + PROXY, {
            method: "post",
            withCredentials: false,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_NAME + url,
            },
            data: JSON.stringify(payload),
            timeout,
          }).then(
            (result) => {
              resolve(result);
            },
            function (error) {
              reject(error);
            }
          );
        }
      });
    });
  };

  const postWithRoundRobin = (url, payload) => {
    let token = `Bearer ${localStorage.getItem("token")}`;
    return new Promise((resolve, reject) => {
      validateTokenLogin().then((result) => {
        if (result.isSuccess) {
          token = `Bearer ${localStorage.getItem("token")}`;
          Axios(PATH + PROXY, {
            method: "post",
            withCredentials: false,
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              "X-Requested-Url": SERVICE_DOMAIN_GKE + SERVICE_ROUND_ROBIN + url,
            },
            data: JSON.stringify(payload),
            timeout,
          }).then(
            (result) => {
              resolve(result);
            },
            function (error) {
              reject(error);
            }
          );
        }
      });
    });
  };

  
  return {
    get,
    post,
    deletes,
    checkUrl,
    consentContentAsync,
    acceptConsentAsync,
    login,
    put,
    apiValidate,
    checkPayment,
    apiLogin,
    generatePaymentURL,
    validateTokenLogin,
    postBase,
    postWithRoundRobin
  };
}
