export const DEV = 'http://localhost:3015/service-nonface2face'
export const SIT = 'http://10.170.49.104';
// export const PROD = 'https://pruforce-uat.prudential.co.id/service-nonface2face';
export const PROD = 'https://services.prudential.co.id/base';
export const PROXY = '/proxy';
export const API_LOGIN = '/api/login';
export const API_VALIDATE = '/api/validate';
export const SERVICE_NAME = '/service-pruleads-nonface2face';
export const SERVICE_ROUND_ROBIN = '/leads-roundrobin-group';
// export const SERVICE_DOMAIN_GKE = 'pf-cekatan-pruleads-backend-prd-pruforce-prd.lb1-pruidlife-prd-az1-zo0pxx.pru.intranet.asia:443';
export const SERVICE_DOMAIN_GKE = '/flink/zuul';
export const SERVICE_DOMAIN_ROUND_ROBIN_GKE = 'pf-leads-round-robin-group-revamp-prd-pruforce-prd.lb1-pruidlife-prd-az1-zo0pxx.pru.intranet.asia:443';
export const SERVICE_DOMAIN_NAME = 'http://prudential.co.id/';

// export const PAYMENT_CHECK_URL = window.REACT_APP_PAYMENT_URL;
// export const xxx = window.REACT_APP_USERNAME_API;
// export const yyy = window.REACT_APP_PASSWORD_API;
// export const zzz = window.REACT_APP_CHANNEL_API;

export const xxx = 'SERVIDLIFENF2FPF';
export const PAYMENT_CHECK_URL = 'https://payment.prudential.co.id/paylink/checkPaymentPruForce';
export const yyy = 'tXULBEWi2gsylzIuV+qP1R1q/DAzQoNV3EZNQbwMZP1Zllxw5MZ7oDfRsdK2e3xSNhUrpzSwLhx0KNUGv6A6o6s4IPTaZ1P0ScAZE2QjKtNFFi24N/DC7R/nECILUc005QN/R+jRDArgKZgaoZUF7EGuzJ3NoOtfEsgC5VMAYoGTLRMbgdzBLdY0hHWEXTBXSEoMPddsNDiUFg/gke2GmdzKQcg+jyP6koFRjY0qzn5T0lxpokN1UGBYX8aAGkiIPzu9NV9rWLoKozTntYKg1yYM0y/i5CzKbG6xSsIicrnapQpsbZ2Pv6ww4oN+7SVBvb6LoPtDHPGp9km7bxzFbw==';
export const zzz = 'partner_nonf2f';

//export const PROD = 'https://pruforce.prudential.co.id';
//need to switch by npm start
//var env = process.env.NODE_ENV || 'prod'
//console.log("ENV : "+process.env.NODE_ENV);
//console.log("using " +(env === 'development'? DEV : PROD) +" as path");
export const PATH = PROD;
// export const SITEKEY = (process.env.NODE_ENV === 'development') ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI":window.REACT_APP_SITE_KEY;
export const SITEKEY = (process.env.NODE_ENV === 'development') ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI":"6LeKGPAUAAAAADgSFpAhw9Xk7TJIqqoN_uGF2OHx";
