/**
 * @author
 * garry.alfanzo@gmail.com
*/

import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

const saveAuthSubsetBlacklistFilter = createBlacklistFilter(
  // 'auth',
  // ['data', 'res', 'action'],
);

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'root',
    storage,
    whitelist: ['layout'],
    transforms: [saveAuthSubsetBlacklistFilter],
  },
};

export default REDUX_PERSIST;