import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import moment from "moment";
import "moment/locale/id";
// Retryjenkins1
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../theme/variables.css";

/* Font variables */
import "../theme/fonts.scss";

// penambahan di bawah
import "react-accessible-accordion/dist/fancy-example.css";

import { addLocaleData, IntlProvider } from "react-intl";
import idLocaleData from "react-intl/locale-data/id";
import enLocaleData from "react-intl/locale-data/en";
import flatten from "flat";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import ReduxStore from "./ReduxStore";
import translation from "../i18n/locales";
// import Layout from '../component/layout/Layout';
import Routes from "../config/Routes";
import GA from "../service/GoogleAnalytics";
import TagManager from "react-gtm-module";

import { CookiesProvider } from "react-cookie";

const tagManagerArgs = {
  gtmId: "GTM-P82N4MT",
  dataLayerName: "PageDataLayer"
};

TagManager.initialize(tagManagerArgs);

addLocaleData(idLocaleData);
addLocaleData(enLocaleData);
global.intl = require("intl");

// const url = window.location.hash;
// const keyWord = 'locale=';
// const regex = new RegExp(`\\b${keyWord}\\b`);
// const valueIndex = url.search(regex);
// const locale = (valueIndex != -1) ? url.substring(valueIndex + 7, valueIndex + 9) : "id"
const locale = "id";
const messages = translation[locale];
const token = localStorage.getItem("token");
const path = window.location.pathname;
// console.log(path);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null, token: token };
  }

  componentDidMount() {
    moment.locale("id");
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    // if (this.state.hasError || (!this.state.token && (!path.includes('login/') && !path.includes('red')))) {
    //   return <h1>404 Not Found{this.state.error}{this.state.info}</h1>;
    // }
    return (
      <CookiesProvider>
        <Provider store={ReduxStore.store} token={this.state.token}>
          <PersistGate loading={null} persistor={ReduxStore.persistor}>
            <IntlProvider
              locale={locale}
              key={locale}
              messages={flatten(messages)}
            >
              <IonApp style={{overflowY:'scroll'}}>
                <IonReactRouter basename="/cekatan-pruleads/">
                  {GA.init() && <GA.RouteTracker />}
                  <Switch>
                    {Routes.ContainerRoutes.map((item, index) => (
                      <Route
                        key={index.toString()}
                        path={item.path}
                        name={item.name}
                        component={item.component}
                      />
                    ))}
                  </Switch>
                  {this.state.hasError ||
                  (!this.state.token &&
                    !path.includes("login/") &&
                    !path.includes("red") &&
                    !path.includes("terms-and-condition") &&
                    !path.includes("form") &&
                    !path.includes("form-success")) ? (
                    <Redirect to="/notfound" />
                  ) : (
                    ""
                  )}
                </IonReactRouter>
              </IonApp>
            </IntlProvider>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    );
  }
}

export default Index;
